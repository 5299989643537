<script setup lang="ts">
import { useVueFlow } from '@vue-flow/core';
import SidebarPanel from './SidebarPanel.vue';
import SidebarTabs from './SidebarTabs.vue';

const canvasStore = useCanvasStore();
const { isShowingPanel, searchQuery } = storeToRefs(canvasStore);
const { resetSidebar } = canvasStore;
const { selectedStep, addingStep } = useSelectedStep();
const { routeName } = useRouteName();
const { removeSelectedElements } = useVueFlow({
  id: 'editor',
});

const isHovering = ref(false);
const sidebar = ref(null);

onClickOutside(sidebar, resetSidebar);

const isExpanded = computed(
  () =>
    routeName.value === 'integrations' ||
    isShowingPanel.value ||
    !!searchQuery.value ||
    !!addingStep.value,
);

watch(selectedStep, (node) => {
  if (node) {
    resetSidebar();
  }
});

watch(isExpanded, (isExpanded) => {
  if (isExpanded && !addingStep.value) {
    removeSelectedElements();
  }
});
</script>
<template>
  <div
    :ref="routeName === 'integrations' ? undefined : sidebar"
    class="rounded-2xl my-auto overflow-hidden max-h-[675px] h-[675px] flex flex-col justify-start items-start bg-container shadow-select border-container"
    :class="
      routeName === 'integrations'
        ? 'w-[975px]'
        : isExpanded
          ? 'w-[640px] 2xl:w-[975px]'
          : isHovering
            ? 'w-72'
            : 'w-20'
    "
    style="transition: width 0.15s ease-in-out"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <div v-if="addingStep" class="w-full px-m flex flex-col">
      <div class="flex flex-row w-full my-m">
        <div class="flex p-s bg-primary-200 radius-s mr-s">
          <UIcon
            name="i-ph-plus-circle-bold"
            class="text-3xl text-primary-500"
          />
        </div>
        <p class="h3 truncate">Add a step</p>
        <UButton
          :padded="false"
          color="gray"
          variant="link"
          icon="i-ph-x"
          class="ml-auto"
          @click="resetSidebar"
        />
      </div>
      <!-- SEARCH -->
      <UInput
        v-model="searchQuery"
        placeholder="Search models..."
        class="w-full"
        icon="i-ph-magnifying-glass-bold"
        color="white"
        autofocus
        size="xl"
      />
    </div>
    <!-- STEP GALLERY -->
    <div
      class="flex flex-row divide-x pt-3.5 justify-start h-full max-h-full overflow-hidden w-full"
    >
      <SidebarTabs :is-expanded="isExpanded || isHovering" />

      <SidebarPanel />
    </div>
  </div>
</template>
<style lang="scss">
.panel-fade-enter-active {
  opacity: 0;
}

.panel-fade-enter-to {
  transition: opacity easing(s);
  opacity: 1;
}

.panel-fade-leave-active {
  opacity: 1;
}

.panel-fade-leave-to {
  transition: opacity 0.4s;
  opacity: 0;
}
</style>
